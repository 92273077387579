body > #root > div {
  height: 100vh;
}

.MuiDivider-root {
  border-top: white;
}

::before {
  border-top: white;
}

.css-d7gmiq-MuiDivider-root::before,
.css-d7gmiq-MuiDivider-root::after {
  border-top: white;
}

div {
  font-family: Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;
  font-size: 14px;
}

.container {
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
  max-width: 100%;
  background-color: #272727;
}

.css-i4bv87-MuiSvgIcon-root {
  font-size: 1rem !important;
}

.css-1vnyxkc-MuiPaper-root-MuiDrawer-paper {
  background-color: #272727 !important;
}

.css-p25sbl-MuiPaper-root-MuiAppBar-root {
  background-color: #272727 !important;
}

tr {
  height: 65px;
}

.App {
  background-color: #272727;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #272727;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background-color: #272727;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.MuiPaper-root {
  background-color: #272727;
}

p {
  color: white;
}

h1 {
  color: white;
}

h3 {
  color: white;
}

.MuiChip-label {
  color: white;
}

.pcl {
  color: #272727;
  color: #747474;
  color: #ff652f;
  color: #ffe400;
  color: #14a76c;
  color: #b53407;
}/*# sourceMappingURL=Style.css.map */